@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 16px;
    font-family: "Inter", sans-serif;
}

body::-webkit-scrollbar {
    width: 12px;
  }
  
  body::-webkit-scrollbar-track {
    background: #0E0E17;
  }
  
  body::-webkit-scrollbar-thumb {
    background-color: #333;
    border-radius: 20px;
    border: 3px solid #0E0E17;
}